import { removeDuplicates } from '../SearchPanel/templateUtils'
import { getAirportsByCode } from '../../services/searchPanelServices'
import { getCountryByCode } from '../../services/dataCaptureService'
import { PAYMENT_TYPES, VOLUME_UNIT_VALUES, WEIGHT_UNIT } from '../../constants'

const shipmentDetailsInitialData = {
	origin: {
		code: '',
		name: '',
		cityCode: '',
		countryCode: '',
		active: '',
		overBookingEnabled: '',
		confirmationMailEnabled: '',
		disclaimerFeatureEnabled: '',
		disclaimerSetThresholdInTonne: ''
	},
	destination: {
		code: '',
		name: '',
		cityCode: '',
		countryCode: '',
		active: '',
		overBookingEnabled: '',
		confirmationMailEnabled: '',
		disclaimerFeatureEnabled: '',
		disclaimerSetThresholdInTonne: ''
	},
	pieces: 0,
	weight: 0,
	weightUnit: '',
	volume: 0,
	volumeUnit: '',
	slacPieces: 0,
	shipmentDescription: '',
	scc: '',
	currencyCode: '',
	paymentType: '',
	handlingInfo: ''
}

const shipperDetailsInitialData = {
	shipperName: '',
	shipperAddress1: '',
	shipperCity: '',
	shipperState: '',
	shipperCountry: {
		countryCode: '',
		countryName: '',
		currencyCode: '',
		currencyName: '',
		regionCode: '',
		status: '',
		confirmationMailEnabled: '',
		disclaimerFeatureEnabled: '',
		disclaimerSetThresholdInTonne: ''
	},
	shipperPostalCode: '',
	shipperPhone: '',
	shipperMail: ''
}

const consigneeDetailsInitialData = {
	consigneeName: '',
	consigneeAddress1: '',
	consigneeCity: '',
	consigneeState: '',
	consigneeCountry: {
		countryCode: '',
		countryName: '',
		currencyCode: '',
		currencyName: '',
		regionCode: '',
		status: '',
		confirmationMailEnabled: '',
		disclaimerFeatureEnabled: '',
		disclaimerSetThresholdInTonne: ''
	},
	consigneePostalCode: '',
	consigneePhone: '',
	consigneeMail: ''
}

const awbChargeDeclarationInitialData = {
	awbCurrencyCode: '',
	declaredValueForCustoms: 0,
	declaredValueForCarriage: 0
  }
const otherCustomsDetailsInitialData ={
	sci: '',
	hccs: ''
  }


export const HawbFailedEditInitialState = {
	hawbResponse: {
	  shipmentDetails:shipmentDetailsInitialData,
	  shipperDetails: shipperDetailsInitialData,
	  consigneeDetails: consigneeDetailsInitialData,
	  sci: '',
	  hccs: '',
	  awbChargeDeclaration: awbChargeDeclarationInitialData,
	  customsInformationDetails: [],
	  currency: '',
	  otherCustomsDetails: otherCustomsDetailsInitialData
	},

	shipmentDetails: shipmentDetailsInitialData,
	shipperDetails: shipperDetailsInitialData,
	consigneeDetails: consigneeDetailsInitialData,
	awbChargeDeclaration:awbChargeDeclarationInitialData,
	customsInformationDetails:[],
	otherCustomsDetails:otherCustomsDetailsInitialData,
	hawbNo: ''
  };
  

export const mapAWBResponse = async (awbDetail) => {
	let awbShipperDetailResponse = awbDetail.awbShipperDetails
		? awbDetail.awbShipperDetails
		: null
	let awbConsigneeDetailResponse = awbDetail.awbConsigneeDetails
		? awbDetail.awbConsigneeDetails
		: null

	const shipperCountry = awbShipperDetailResponse.customerCountryCode
		? await getCountryByCode(awbShipperDetailResponse.customerCountryCode)
		: null
	const consigneeCountry = awbConsigneeDetailResponse.customerCountryCode
		? await getCountryByCode(awbConsigneeDetailResponse.customerCountryCode)
		: null

	let awbResponse = {
		awbConsignmentDetails: awbDetail.awbConsignmentDetails
			? awbDetail.awbConsignmentDetails
			: null,
		awbShipperDetails: awbShipperDetailResponse
			? {
				shipperName: awbShipperDetailResponse.customerName
					? awbShipperDetailResponse.customerName
					: '',
				shipperAddress1: awbShipperDetailResponse.customerStreetAddress
					? awbShipperDetailResponse.customerStreetAddress
					: '',
				shipperCity: awbShipperDetailResponse.customerPlace
					? awbShipperDetailResponse.customerPlace
					: '',
				shipperState: awbShipperDetailResponse.customerState
					? awbShipperDetailResponse.customerState
					: '',
				shipperCountry: shipperCountry ? shipperCountry : '',
				shipperPostalCode: awbShipperDetailResponse.customerZipCode
					? awbShipperDetailResponse.customerZipCode
					: '',
				shipperAccountNumber: awbShipperDetailResponse.customerAccountNumber
					? awbShipperDetailResponse.customerAccountNumber
					: '',
				shipperPhone:
					awbShipperDetailResponse.customerContactDetails &&
						awbShipperDetailResponse.customerContactDetails.customerContact &&
						awbShipperDetailResponse.customerContactDetails.customerContact[0]
							.customerPhoneNumber
						? awbShipperDetailResponse.customerContactDetails
							.customerContact[0].customerPhoneNumber
						: '',
				shipperMail:
					awbShipperDetailResponse.customerContactDetails &&
						awbShipperDetailResponse.customerContactDetails.customerContact &&
						awbShipperDetailResponse.customerContactDetails.customerContact[0]
							.customerEMailId
						? awbShipperDetailResponse.customerContactDetails
							.customerContact[0].customerEMailId
						: '',
			}
			: null,
		awbConsigneeDetails: awbConsigneeDetailResponse
			? {
				consigneeName: awbConsigneeDetailResponse.customerName
					? awbConsigneeDetailResponse.customerName
					: '',
				consigneeAddress1: awbConsigneeDetailResponse.customerStreetAddress
					? awbConsigneeDetailResponse.customerStreetAddress
					: '',
				consigneeCity: awbConsigneeDetailResponse.customerPlace
					? awbConsigneeDetailResponse.customerPlace
					: '',
				consigneeState: awbConsigneeDetailResponse.customerState
					? awbConsigneeDetailResponse.customerState
					: '',
				consigneeCountry: consigneeCountry ? consigneeCountry : '',
				consigneePostalCode: awbConsigneeDetailResponse.customerZipCode
					? awbConsigneeDetailResponse.customerZipCode
					: '',
				consigneeAccountNumber:
					awbConsigneeDetailResponse.customerAccountNumber
						? awbConsigneeDetailResponse.customerAccountNumber
						: '',
				consigneePhone:
					awbConsigneeDetailResponse.customerContactDetails &&
						awbConsigneeDetailResponse.customerContactDetails.customerContact &&
						awbConsigneeDetailResponse.customerContactDetails.customerContact[0]
							.customerPhoneNumber
						? awbConsigneeDetailResponse.customerContactDetails
							.customerContact[0].customerPhoneNumber
						: '',
				consigneeMail:
					awbConsigneeDetailResponse.customerContactDetails &&
						awbConsigneeDetailResponse.customerContactDetails.customerContact &&
						awbConsigneeDetailResponse.customerContactDetails.customerContact[0]
							.customerEMailId
						? awbConsigneeDetailResponse.customerContactDetails
							.customerContact[0].customerEMailId
						: '',
			}
			: null,
		awbAgentDetails: awbDetail.awbAgentDetails
			? awbDetail.awbAgentDetails
			: null,
	}

	return awbResponse
}

export const mapHawbResponse = async (awbDetail) => {
	let houseDetails = awbDetail.houseDetails[0]
	let commodityCode = houseDetails?.awbRateLineDetails?.awbRateLine[0]?.commodityCode
	const origin = await getAirportsByCode(houseDetails.origin)
	const destination = await getAirportsByCode(houseDetails.destination)
	const shipperCountry = await getCountryByCode(houseDetails.shipperCountry)
	const consigneeCountry = await getCountryByCode(houseDetails.consigneeCountry)
	let hawbResponse = houseDetails && {
		shipmentDetails: {
			origin: origin ? origin : null,
			destination: destination ? destination : null,
			pieces: houseDetails.statedPieces ? houseDetails.statedPieces : '',
			weight: houseDetails.statedWeight ? houseDetails.statedWeight : '',
			weightUnit: houseDetails.statedWeightUnit
				? houseDetails.statedWeightUnit
				: '',
			volume: houseDetails.statedVolume ? houseDetails.statedVolume : '',
			volumeUnit: houseDetails.statedVolumeUnit
				? houseDetails.statedVolumeUnit
				: '',
			slacPieces: houseDetails.slacPieces,
			shipmentDescription: houseDetails.shipmentDescription,
			// scc: houseDetails.scc && getSccList(houseDetails.scc)
			scc: houseDetails.scc,
			declaredValueForCustoms:
				houseDetails.awbChargeDeclaration &&
					houseDetails.awbChargeDeclaration.declaredValueForCustoms
					? houseDetails.awbChargeDeclaration.declaredValueForCustoms
					: null,
			currencyCode:
				houseDetails.awbChargeDeclaration &&
					houseDetails.awbChargeDeclaration.awbCurrencyCode
					? houseDetails.awbChargeDeclaration.awbCurrencyCode
					: '',
			paymentType: houseDetails.awbChargeDeclaration
				&& houseDetails.awbChargeDeclaration.prepaidCollectChargeDeclaration
				&& houseDetails.awbChargeDeclaration.prepaidCollectChargeDeclaration.prepaidCollectIndicatorWgtValChgs
				? houseDetails.awbChargeDeclaration.prepaidCollectChargeDeclaration.prepaidCollectIndicatorWgtValChgs
				: PAYMENT_TYPES.PP,
			handlingInfo: houseDetails.awbHandlingInfoDetails !== null ? houseDetails.awbHandlingInfoDetails.otherServiceInformation[0] : '',
		},

		commodityCode: commodityCode ? commodityCode : null,
		shipperDetails: {
			shipperName: houseDetails.shipperName ? houseDetails.shipperName : '',
			shipperAddress1: houseDetails.shipperAddress1
				? houseDetails.shipperAddress1
				: '',
			shipperCity: houseDetails.shipperCity ? houseDetails.shipperCity : '',
			shipperState: houseDetails.shipperState ? houseDetails.shipperState : '',
			shipperCountry: shipperCountry ? shipperCountry : null,
			shipperPostalCode: houseDetails.shipperPostalCode
				? houseDetails.shipperPostalCode
				: '',
			shipperAccountNumber: houseDetails.shipperAccountNumber
				? houseDetails.shipperAccountNumber
				: '',
			shipperPhone: houseDetails.shipperPhone ? houseDetails.shipperPhone : '',
			shipperMail: houseDetails.shipperEmail ? houseDetails.shipperEmail : '',
		},
		consigneeDetails: {
			consigneeName: houseDetails.consigneeName
				? houseDetails.consigneeName
				: '',
			consigneeAddress1: houseDetails.consigneeAddress1
				? houseDetails.consigneeAddress1
				: '',
			consigneeCity: houseDetails.consigneeCity
				? houseDetails.consigneeCity
				: '',
			consigneeState: houseDetails.consigneeState
				? houseDetails.consigneeState
				: '',
			consigneeCountry: consigneeCountry ? consigneeCountry : null,
			consigneePostalCode: houseDetails.consigneePostalCode
				? houseDetails.consigneePostalCode
				: '',
			consigneeAccountNumber: houseDetails.consigneeAccountNumber
				? houseDetails.consigneeAccountNumber
				: '',
			consigneePhone: houseDetails.consigneePhone
				? houseDetails.consigneePhone
				: '',
			consigneeMail: houseDetails.consigneeEmail
				? houseDetails.consigneeEmail
				: '',
		},

		sci: houseDetails.customsOriginCode ? houseDetails.customsOriginCode : null,
		hccs: getHCC(houseDetails?.awbRateLineDetails?.awbRateLine),
		awbChargeDeclaration: houseDetails.awbChargeDeclaration,
		customsInformationDetails: getCustomsInformationDetails(houseDetails),
		currency: houseDetails.awbChargeDeclaration.awbCurrencyCode
			? houseDetails.awbChargeDeclaration.awbCurrencyCode
			: '',
		otherCustomsDetails: {
			sci: houseDetails.customsOriginCode ? houseDetails.customsOriginCode : null,
			hccs: getHCC(houseDetails?.awbRateLineDetails?.awbRateLine),
		},
	}
	return hawbResponse
}

const getHCC = (awbRateLine) => {

	if (
		awbRateLine &&
		awbRateLine[0]?.harmonizedCommodityCodes &&
		awbRateLine[0]?.harmonizedCommodityCodes?.harmonizedCommodityCode?.length
	) {
		return awbRateLine[0]?.harmonizedCommodityCodes?.harmonizedCommodityCode.join()
	} else {
		return ''
	}
}


const getSccList = (sccCodes) => {
	if (sccCodes && sccCodes !== '') {
		const sccArr = sccCodes.split(',')
		let sccDetails = []
		sccArr.forEach(function (scc) {
			const sccDetail = {
				scc: scc,
				sccDescription: null,
			}
			sccDetails.push(sccDetail)
		})
		return removeDuplicates(sccDetails)
	}
}

const getCustomsInformationDetails = (details) => {
	const customsInformationDetails = details.otherCustomsInformationDetails
	let customsDetails = []
	if (
		customsInformationDetails &&
		customsInformationDetails.otherCustomsInformation &&
		customsInformationDetails.otherCustomsInformation.length
	) {
		customsInformationDetails.otherCustomsInformation.forEach(function (
			info,
			index
		) {
			let customsInfo = {
				informationId: info.informationIdentifier,
				customsInformationId: info.customsInformationIdentifier,
				supplementaryCustomsInformation:
					info.supplementaryCustomsControlInformation,
				countryCode: info.countryCode,
			}
			if (index > 0) {
				customsInfo.isNew = true
			}
			customsDetails.push(customsInfo)
		})
		return customsDetails
	} else {
		const info = {
			informationIdentifier: '',
			customsInformationIdentifier: '',
			supplementaryCustomsControlInformation: '',
			countryCode: '',
		}
		customsDetails.push(info)
		return customsDetails
	}


}


export const mapHawbFailedRecordResponse = async (failedHawbRecord) => {
	const {
		hawbNo,
		origin,
		destination,
		shipmentDescription,
		scc,
		pieces,
		slacPieces,
		weight,
		volume,
		handlingInfo,
		hsCode,
		shipperName,
		shipperAddress,
		shipperCity,
		shipperState,
		shipperCountry,
		shipperZip,
		shipperPhone,
		shipperEmail,
		consigneeName,
		consigneeAddress,
		consigneeCity,
		consigneeState,
		consigneeCountry,
		consigneeZip,
		consigneePhone,
		consigneeEmail,
		sci,
		paymentType,
		currency,
		dvCustoms,
		dvCarriage,
		weightUnit,
		volumeUnit,
		customsInfo,
	} = failedHawbRecord?.hawbData;
	let originData = origin
	let destinationData = destination
	let shipperCountryData = shipperCountry
	let consigneeCountryData = consigneeCountry
	if(origin){
		originData = await getAirportsByCode(origin)
	}
	if(destination){
		destinationData = await getAirportsByCode(destination)
	}
	if(shipperCountry){
		shipperCountryData = await getCountryByCode(shipperCountry)
	}
	if(consigneeCountry){
		consigneeCountryData = await getCountryByCode(consigneeCountry)
	}
	let hawbResponse = {
		shipmentDetails: {
			origin: originData || null,
			destination: destinationData || null,
			pieces: pieces ? pieces + '' : '',
			weight: weight ? weight + '' : '',
			weightUnit,
			volume: volume ?  volume + '': '',
			volumeUnit,
			slacPieces: slacPieces === 0  ? '' : slacPieces + '',
			shipmentDescription,
			scc,
			currencyCode: currency,
			declaredValueForCustoms: dvCustoms + '',
			paymentType,
			handlingInfo,
		},
		shipperDetails: {
			shipperName,
			shipperAddress1: shipperAddress,
			shipperCity,
			shipperState,
			shipperCountry: shipperCountryData || null,
			shipperPostalCode: shipperZip,
			shipperPhone,
			shipperMail: shipperEmail,
		},
		consigneeDetails: {
			consigneeName,
			consigneeAddress1: consigneeAddress,
			consigneeCity,
			consigneeState,
			consigneeCountry: consigneeCountryData || null,
			consigneePostalCode: consigneeZip,
			consigneePhone,
			consigneeMail: consigneeEmail
		},

		sci,
		hccs: hsCode,
		awbChargeDeclaration: {
			awbCurrencyCode: currency,
			declaredValueForCustoms: dvCustoms + '',
			declaredValueForCarriage: dvCarriage + ''
		},
		customsInformationDetails: customsInfo?.length > 0 ?
			customsInfo?.map(eachInfo => ({ informationId: eachInfo?.infoId, customsInformationId: eachInfo?.cusInfoId, supplementaryCustomsInformation: eachInfo?.suppCusInfo, countryCode: eachInfo?.isocCode }))
			: [],
		currency,
		otherCustomsDetails: {
			sci,
			hccs: hsCode,
		},
	}
	const shipmentDetails = hawbResponse.shipmentDetails
	const shipperDetails = hawbResponse.shipperDetails
	const consigneeDetails =  hawbResponse.consigneeDetails
	const awbChargeDeclaration = hawbResponse.awbChargeDeclaration;
	const customsInformationDetails = hawbResponse.customsInformationDetails
	const otherCustomsDetails = hawbResponse.otherCustomsDetails

	return { hawbResponse, shipmentDetails, shipperDetails, consigneeDetails,awbChargeDeclaration,customsInformationDetails,otherCustomsDetails, hawbNo, oldHawbNo: hawbNo, }
}


