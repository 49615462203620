import axios from 'axios'
import envConfigs from '../configs/envConfigs'
import { TIMEOUT_TIME } from '../constants'
import { portalAuthHttpClient } from '../utils/HttpRequestUtils'
import {
	getQuoteRequestBody,
	getSaveBookingRequestBody,
	getSaveQuoteRequest,
	getcheckShipmentIsBigOrOHGRequest,
	getFileList,
} from '../utils/RequestBodyUtils'
import { v4 as uuidv4 } from 'uuid'

export const getPromoCodeResponse = async (searchFormData) => {
	const data = getQuoteRequestBody(searchFormData)
	const url = `${envConfigs.baseUrl}${envConfigs.getValidatePromoCodeUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}


export const getValidateSpotRateResponse = async (searchFormData) => {
	const data = getQuoteRequestBody(searchFormData)
	const url = `${envConfigs.baseUrl}${envConfigs.getValidateSpotRateUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const getQuoteResponse = async (searchFormData) => {
	const data = getQuoteRequestBody(searchFormData)
	const url = `${envConfigs.baseUrl}${envConfigs.getQuoteSearchResultsUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const getSaveBookingResponse = async (
	searchFormData,
	selectedFlight,
	spotRatePayload,
	awbNumber
) => {
	let data = getSaveBookingRequestBody(
		searchFormData,
		selectedFlight,
		spotRatePayload,
		awbNumber
	)
	const url = `${envConfigs.baseUrl}${envConfigs.saveBookingUrl}`
	const response = await portalAuthHttpClient.post(url, data)

	//let petFiles = await getFileList();

	/*if (response?.data?.bookingResponseDetails) {
		try {
			//savePetFileList(`607-${response.data.bookingResponseDetails.masterDocumentNumber}`, petFiles)
			savePetFileList(`${response.data.bookingResponseDetails.shipmentPrefix}-${response.data.bookingResponseDetails.masterDocumentNumber}`, petFiles)
		}
		catch {
			console.log("File upload Failed")
		}
	}*/

	return response.data
}

export const savePetFileList = async (
	awbNumber,
	petFiles,
) => {

	const data = {
		multipartFiles: petFiles,
		awbNumber: awbNumber,
	}
	const url = `${envConfigs.baseUrl}${'/booking/bookingdetails/saveAttachments'}`
	const response = await portalAuthHttpClient.post(url, data)

}

export const updateBooking = async (
	searchFormData,
	selectedFlight,
	awbNumber,
	spotRatePayload
) => {
	let data = getSaveBookingRequestBody(
		searchFormData,
		selectedFlight,
		spotRatePayload,
		awbNumber
	)
	const url = `${envConfigs.baseUrl}${envConfigs.updateBookingUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const validateBooking = async (
	searchFormData,
	selectedFlight,
	awbNumber,

) => {
	let data = getSaveBookingRequestBody(
		searchFormData,
		selectedFlight,
		null,
		awbNumber
	)
	if (data) {
		data.bookingDetails.quotationID = uuidv4()
	}
	const url = `${envConfigs.baseUrl}${envConfigs.validateBookingUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const saveQuote = async (
	searchFormData,
	selectedFlight,
	quotationDetails

) => {
	let data = getSaveQuoteRequest(searchFormData, selectedFlight, quotationDetails)
	const url = `${envConfigs.baseUrl}${envConfigs.saveQuoteUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const findQuote = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.findQuoteUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const deleteQuote = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.deleteQuoteUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const listQuotes = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.listQuoteUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const checkShipmentIsBigOrOHG = async (searchFormData) => {
	let data = getcheckShipmentIsBigOrOHGRequest(searchFormData)

	const url = `${envConfigs.baseUrl}${envConfigs.checkBigOrOHGUrl}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const getValidationRule = async (captureType) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getValidationRuleUrl}/${captureType}`
	const response = await portalAuthHttpClient.get(url)
	return response.data
}

export const getRestrictedList = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getBookingRestriction}`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}


