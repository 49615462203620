import React, { useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../LoadingIndicator";
import "./HawbBulkUpload.scss";
import getCurrentUser from "../../utils/getCurrentUser";
import { doHawbBulkUpload } from "../../services/dataCaptureService";

const HawbBulkUploadPopup = ({
  handlePopupClose,
  isLoading,
  setIsLoding,
  awbNumber,
  showSuccess,
  getBulkUploadStatus,
  setFailedRecords,
  setBulkUploadStatus,
  setBulkUploadDone,
  failedRecord,
  validationRule,
  hsCodeMandatory,
  hawbNumbers,
  setFailedRecordsCount,
}) => {
  const { t } = useTranslation(["labels", "errors"]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const isExcelFile = (file) => {
    const validExtensions = [".xlsx", ".xls"];
    const filePath = file.name?.toLowerCase();
    return validExtensions.some((ext) => filePath.endsWith(ext));
  };


  const uploadFile = async () => {
    if (selectedFile) {
      setFailedRecords(null)
      setBulkUploadStatus(null)
      setFailedRecordsCount(0)
      const currentUser = getCurrentUser();
      if (currentUser) {
        var formData = new FormData();

        formData.append("userId", currentUser.userId);
        formData.append("agentCode", currentUser.agentCode);
        formData.append("fileName", selectedFile.name);
        formData.append("emailId", currentUser.email);
        formData.append("firstName", currentUser.firstName);
        formData.append("hawbBulkUploadExcel", selectedFile);
        formData.append("awbNo", awbNumber);
        formData.append("hscodeEnabled", hsCodeMandatory);
        if (validationRule && validationRule.length > 0) {
          formData.append("hawbValidationRules", JSON.stringify(validationRule[0]));
        }
        formData.append("hawbNumbers", hawbNumbers);
        try {
          setIsLoding(true);
          setBulkUploadDone(true)
          const response = await doHawbBulkUpload(formData);
          if (response?.status === "success") {
            setIsLoding(false);
            showSuccess();
            handlePopupClose();
            getBulkUploadStatus(awbNumber)
          } else {
            setError("Excel Upload Failed");
          }
        } catch (error) {
          setError(error.message);
        }
      }
    }
    setIsLoding(false);
  };

  const setFileToState = (files) => {
    setError('')
    if (files && files[0]) {
      if (isExcelFile(files[0])) {
        setSelectedFile(files[0]);
      }else{
        setError('Please choose an Excel file (xlsx/xls)')
        setSelectedFile(null)
      }
    }
  }

  const onUploadButtonClick = () => {
    if(!error)
    if (failedRecord) {
      setShowConfirmationDialog(true);
    } else {
      uploadFile();
    }
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    setFileToState(files)
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const { files } = e.dataTransfer;
    setFileToState(files)
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleYesClick = () => {
    setShowConfirmationDialog(false);
    setFailedRecords(null)
    setBulkUploadStatus(null)
    uploadFile(); 
  };

  const handleNoClick = () => {
    setShowConfirmationDialog(false); 
    handlePopupClose();
  };

  return (
    <div className="cargo-portal-container dialog-container hawb-bulk-upload">
      <div className="portal-dialog p-fluid">
        <Dialog
          visible
          modal
          closable={true}
          header=""
          onHide={handlePopupClose}
          style={{ width: "500px" }}
        >
          <h3 className="hawbpopup-header text-center mb-5">
            HAWB Bulk Upload
          </h3>
          <a
            className="hawbpopup-exceldownload"
            href={process.env.PUBLIC_URL + "/HAWB_Upload_Template_v1.xlsx"}
            role="button"
            target="_blank"
          >
            <img src={require("../../assets/images/download.png").default} />
            <div className="downld_temp">
              {t("PERMANENT_BOOKING.DOWNLOAD_TMP")}
            </div>
          </a>
          <div className="hawbpopup-input-session">
            <label
              className="file-input-section"
              htmlFor="formFile"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              <input
                className="d-none"
                type="file"
                id="formFile"
                onChange={handleFileChange}
              />
              <div style={{ height: "80px", width: "100%" }}>
                <img
                  src={require("../../assets/images/UploadExcel.png").default}
                />
                <p className="m-0">{t("PERMANENT_BOOKING.DROP_CLICK")}</p>
              </div>
              <div style={{ height: "20px", marginTop: "3px" }}>
                {selectedFile ? selectedFile.name : ""}
              </div>
            </label>
          </div>
          <span className="error-show-area form-item__error">
            {error ? error : " "}
          </span>
          <div className="hawbpopup-footer-btns">
            <button
              type="button"
              className="button"
              onClick={(e) => {
                onUploadButtonClick();
              }}
            >
              Upload
            </button>
            <button
              type="button"
              className="button secondary"
              onClick={handlePopupClose}
            >
              Cancel
            </button>
          </div>
        </Dialog>
        {isLoading && <LoadingIndicator />}

        {/* Confirmation Dialog */}
        {showConfirmationDialog && (
          <Dialog
            visible
            modal
            closable={false}
            header="Confirmation"
            style={{ width: "450px" }}
            className="hawbpopup-warning-popup"
          >
            <p className="d-flex justify-content-center mt-1">
              Outstanding errors exist in the previous record. These errors will
              be discarded on the new Excel Upload. Do you wish to continue?
            </p>
            <div className="confirmation-dialog-buttons d-flex justify-content-around mt-4">
              <button
                type="button"
                className="button"
                onClick={handleYesClick}
              >
                Yes
              </button>
              <button
                type="button"
                className="button secondary"
                onClick={handleNoClick}
              >
                No
              </button>
            </div>
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default HawbBulkUploadPopup;
