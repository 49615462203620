import { SEARCH_FORM } from "../redux/constants";

export default {
	ACCESS_TOKEN: 'accessToken',
	REFRESH_TOKEN: 'refreshToken',
	USER_DETAILS: 'userDetails',
	SUBJECT: 'subject',
	MANAGE_BOOKING_SEARCH_INFO: 'bookingSearchFormData',
	SEARCH_FORM_DATA_BOOKING: 'searchObject',
	SELECTED_FLIGHT_BOOKING: 'flightSelected',
	GEO_LOCATION: 'edge.geoLocation',
	SSO_REFRESH_URL: 'SSORefreshUrl',
	SSO_TYPE: 'SSOType',
	PERMANENT_BKG_FLG: 'PermanentBkgFlg',
	HAWB_UPLD_FLG: 'HAWBUpldFlg',
	PLACI_USER_DETAILS: 'placiUserDetails',
	AGENT_COUNTRY: 'agentCountry',
	GSA_AGENT_DETAILS : 'GsaAgentDetails',
	I18NEST_LNG: 'i18nextLng',
	AIRPORTS_MASTER: 'airportsMaster'
}
