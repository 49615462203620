import React from "react";
import "./FailedRecordTable.scss";

const fomatDataRow = (failedDataObj) => {
  let { hawbNo, hawbData, status, reason, errorCode } = failedDataObj;
  const hawbDataFormatted = JSON.parse(hawbData?.replace(/\n/g, ""));
  if (hawbNo && hawbDataFormatted) {
    const {
      pieces,
      slacPieces,
      weight,
      weightUnit,
      volume,
      volumeUnit,
      origin,
      destination,
      shipmentDescription,
      handlingInfo,
      scc,
    } = hawbDataFormatted;
    let quantityString = "";
    if (pieces) {
      quantityString = `${pieces} pcs`;
    }
    if (weight) {
      quantityString = `${quantityString} | ${weight} ${weightUnit || "KG"} `;
    }
    if (volume) {
      quantityString = `${quantityString} | ${volume} ${volumeUnit} `;
    }
    if (slacPieces) {
      quantityString = `${quantityString} | ${slacPieces} SLAC`;
    }
    const orginDestination = `${origin}-${destination}`;
    reason = reason.replace(/,\s*$/, "");
    reason = reason.replace(';', "; ");
    const reasonArray = reason.split(";").map((item) => item.trim()); 
    let displayReason = reasonArray.join(",\n"); 

    if (reasonArray.length > 2) {
      displayReason = "There are 2 or more errors in the record. Please correct the errors.";
    }

    return {
      hawbNo,
      orginDestination,
      shipmentDescription,
      quantityString,
      handlingInfo,
      hawbDataFormatted,
      reason: displayReason ,
      scc,
      errorCode,
    };
  }
};

const FailedRecordsTable = ({ data, onViewClick }) => {
  return (
    <div className="failed-records-table mt-4">
      {data.length > 0 ? (
        <table className="failed-table">
          <thead className="failed-table-header">
            <tr>
              <th className="col-2">HAWB</th>
              <th className="col-2">Orgin-Destination</th>
              <th className="col-2">Description</th>
              {/*<th className="col-1">SCC</th>*/}
              <th className="col-2">Quantity</th>
              <th className="col-3">Errors</th>
              <th className="col-1"> </th>
            </tr>
          </thead>
          <tbody className="failed-table-body">
            {data.map((row, index) => {
              const formattedData = fomatDataRow(row);
              if (formattedData) {
                const {
                  hawbNo,
                  orginDestination,
                  shipmentDescription,
                  quantityString,
                  handlingInfo,
                  hawbDataFormatted,
                  reason,
                  errorCode,
                } = formattedData;
                return (
                  <tr
                    key={index}
                    className={data?.length === index + 1 ? "border-0" : ""}
                  >
                    <td>
                      {hawbNo}
                    </td>
                    <td>{orginDestination}</td>
                    <td>{shipmentDescription}</td>
                   {/* <td>{scc}</td>*/}
                    <td>{quantityString}</td>
                    <td style={{ whiteSpace: "pre-wrap" }}>{reason}</td>
                    <td className="link view-link" onClick={() => onViewClick(index, {...row,hawbData:hawbDataFormatted}, reason, errorCode)}>View</td>
                  </tr>
                );
              }
              return <></>;
            })}
          </tbody>
        </table>
      ) : (
        <p className="no-records">No failed records to display.</p>
      )}
    </div>
  );
};

export default FailedRecordsTable;
