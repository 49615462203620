import axios from 'axios'
import envConfigs from '../configs/envConfigs'
import { TIMEOUT_TIME } from '../constants'
import { getHeaders, portalAuthHttpClient } from '../utils/HttpRequestUtils'
import {
	getSaveAwbRequest,
	getSaveAcceptTnCRequest,
	getAwbPrintRequest,
	getSaveHAWBRequest,
} from '../utils/RequestBodyUtils'

export const getAWB = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getAWBDetailsUrl}`
	const response = await portalAuthHttpClient.post(url, data)
    return response.data
}

export const saveAWB = async (awbDetails) => {
	const url = `${envConfigs.baseUrl}${envConfigs.saveAWBDetailsUrl}`
	const data = getSaveAwbRequest(awbDetails)
	const response = await portalAuthHttpClient.post(url, data)
    return response.data
}

export const saveAcceptTnC = async (flag) => {
	const url = `${envConfigs.baseUrl}${envConfigs.saveDataCaptureTnCUrl}`
	const data = getSaveAcceptTnCRequest(flag)
	const response = await portalAuthHttpClient.post(url, data)
    return response.data
}

export const printAWB = async (shipmentIdentifierDetails, printName) => {
	const url = `${envConfigs.baseUrl}${envConfigs.printAwbDetailsUrl}`
	const data = getAwbPrintRequest(
		shipmentIdentifierDetails,
		printName
	)
	const response = await portalAuthHttpClient.post(url, data)
    return response.data
}

export const findHAWBDetails = async (data) => {
	const headers = getHeaders()
	const url = `${envConfigs.baseUrl}${envConfigs.findHAWBDetailsUrl}`
	const response = await portalAuthHttpClient.post(url, data)
    return response.data
}

export const saveHAWBDetails = async (hawbSaveRequest) => {
	const data = getSaveHAWBRequest(hawbSaveRequest)
	const url = `${envConfigs.baseUrl}${envConfigs.saveHAWBDetailsUrl}`
	const response = await portalAuthHttpClient.post(url, data)
    return response.data
}

export const deleteHAWBDetails = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.deleteHAWBDetailsUrl}`
	const response = await portalAuthHttpClient.post(url, data)
    return response.data
}

export const getCountryByCode = async (code) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getCountryByCodeUrl}/${code}`
	const response = await portalAuthHttpClient.get(url)
    return response.data
}

export const getCountries = async (queryText) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getCountriesUrl}/${queryText}`
	const response = await portalAuthHttpClient.get(url)
    return response.data
}

export const getCountryByName = async (countryName) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getCountryByNameUrl}/${countryName}`
	//const url = `http://localhost:3001/masterdata/countryByName`
	const response = await portalAuthHttpClient.get(url)
    return response.data
}

export const getChargeHeads = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.getChargeHeads}`
	const response = await portalAuthHttpClient.get(url)
    return response.data
}

export const getCurrencyList = async () => {
	const url = `${envConfigs.baseUrl}${envConfigs.getCurrencyListUrl}`
	const response = await portalAuthHttpClient.get(url)
    return response.data
}

export const doHawbBulkUpload = async (bulkUploadReq) => {
	const url = `${envConfigs.baseUrl}${envConfigs.hawbBulkUpload}`
	//const url = `http://localhost:8080/api${envConfigs.getHawbBulkUpload}`
	const response = await portalAuthHttpClient.post(url,bulkUploadReq)
    return response.data
}

export const getHawbMassUploadDetails = async (agentCode, awbNumber) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getHawbMassUploadDetails}/${agentCode}/${awbNumber}`
	//const url = `http://localhost:8080/api/booking/hawb-bulk-upload/retrieve/${agentCode}/${awbNumber}`
	const response = await portalAuthHttpClient.get(url)
    return response.data
}

export const getHawbMassUploadRecords = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.getHawbMassUploadRecords}`
	//const url = `http://localhost:8080/api/booking/hawb-bulk-upload/retrieve-hawbs`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

export const resubmitHawbFailedRecord = async (data) => {
	const url = `${envConfigs.baseUrl}${envConfigs.resubmitHawb}`
	//const url = `http://localhost:8080/api/booking/hawb-bulk-upload/resubmit-hawb`
	const response = await portalAuthHttpClient.post(url, data)
	return response.data
}

